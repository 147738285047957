import { EventEmitter } from "events";
// services
import Glient from "./glient";
import User from "./user";
import Gateway from "./gateway";
import Constants from "./constants";
import { sortAlphabetically } from "./l10n";
// types
import type {
	CmdGatewayActionGatewayDeleteRules, CmdGatewayActionGatewaySaveRules, CmdGatewayActionGatewayDefault,
	MsgBroadcastRules, MsgBroadcastRulesDeleted,
} from "../types/message";
import type { GatewayId, GatewayStatus } from "../types/gateway";
import type { RxCallback } from "../types/roc-ws";
import type { RuleId, Rules as RulesT, Rule } from "../types/rule";
import type { Callback } from "../types/misc";

export const RULE_TYPE_IMAGE_PATHS = {
	[Constants.Rule.Type.Template]: "rules/rules-template.svg",
	[Constants.Rule.Type.Scheduler]: "rules/rules-scheduler.svg",
	[Constants.Rule.Type.Advanced]: "rules/rules-advanced.svg",
} as const;

/**
 * A in-memory storage for rules of a gateway. Once the rules are loaded,
 * they are updated as soon as the rules broadcasts are received.
 * So, the rules are loaded and kept in memory and updated on broadcasts.
 * Also, responsible for storing the selected rule in the localstorage
 * and fetching it.
 *
 * @event rulesFetched
 * @event rules_deleted
 * @event rules
 *
 * @see message-handler.js
 * @class Rules
 * @extends EventEmitter
 */
class Rules extends EventEmitter {

	#loaded = false;
	#fetchedAt = 0;
	#gatewayId: GatewayId | null = null;
	#rules: RulesT = [];

	constructor() {
		super();

		this.handleSelectedGatewayChanged = this.handleSelectedGatewayChanged.bind(this);
		this.handleGatewayStatusChanged = this.handleGatewayStatusChanged.bind(this);

		Gateway.on("selectedGatewayChanged", this.handleSelectedGatewayChanged);
		Gateway.on("statusChanged", this.handleGatewayStatusChanged);
	}

	/**
	 * On gateway changed, rules are fetched for the selected gateway.
	 */
	private handleSelectedGatewayChanged(/*gateway*/): void {
		this.#loaded = false;
		// this.getRules(() => {});
	}

	private handleGatewayStatusChanged(status: GatewayStatus | undefined): void {
		if (status === Constants.Gateway.Status.Unreachable) {
			this.#loaded = false;
			this.#fetchedAt = 0;
			this.#rules = [];
			this.emit("reset");
			this.emit("rulesChanged", []);
		}
	}

	static #sortFunc(ruleA: Rule, ruleB: Rule): number {
		return sortAlphabetically(ruleA.name, ruleB.name);
	}

	public isLoaded(): boolean {
		return this.#loaded;
	}

	/**
	 * @returns {Object[]} List of rules.
	 */
	public get(): Readonly<RulesT> {
		return [...this.#rules];
	}

	/**
	 * Fetches the rules from server and stores in memory.
	 * @event rulesFetched
	 * @param {Function} callback
	 */
	public getRules(callback: Callback<Readonly<RulesT>>): void {
		const selectedGatewayId = Gateway.selectedGatewayId;
		if (selectedGatewayId === undefined || Gateway.getMessage() !== null) {
			const error = new Error("No Gateway selected or unreachable");
			callback(error, [...this.#rules]);
		} else if (this.#loaded && this.#gatewayId === selectedGatewayId && User.loggedInAt <= this.#fetchedAt) {
			callback(null, [...this.#rules]);
		} else {
			this.#fetchRules(selectedGatewayId, (error, msg) => {
				this.#gatewayId = selectedGatewayId;
				this.#fetchedAt = Date.now();
				if (!error && msg?.payload.status === "ok") {
					this.#loaded = true;
					this.#rules = (msg.payload.data as RulesT).toSorted(Rules.#sortFunc);
				} else {
					this.#rules = [];
				}
				this.emit("rulesFetched");
				callback(error, [...this.#rules]);
			});
		}
	}

	#fetchRules(gatewayId: GatewayId, callback: RxCallback<CmdGatewayActionGatewayDefault>): void {
		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "getRules",
			params: [],
			gatewayId: gatewayId,
		} as const satisfies CmdGatewayActionGatewayDefault;
		Glient.send(cmd, (error, msg) => {
			callback(error, msg);
		});
	}

	public getRuleById(ruleId: RuleId): Rule | undefined {
		return this.#rules.find((rule) => (rule.id === ruleId));
	}

	/**
	 * Saves the rule.
	 * @param {Array<Object>} rules
	 * @param {Function}      callback
	 */
	public saveRules(rules: Array<Partial<Rule>>, callback: RxCallback<CmdGatewayActionGatewaySaveRules>): void {
		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "saveRules",
			params: [{
				rules: rules,
			}],
			gatewayId: Gateway.selectedGatewayId!,
		} as const satisfies CmdGatewayActionGatewaySaveRules;
		Glient.send(cmd, (error, msg) => {
			callback(error, msg);
		});
	}

	public deleteRule(ruleId: RuleId, callback: RxCallback<CmdGatewayActionGatewayDeleteRules>): void {
		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "deleteRules",
			params: [{
				rules: [{
					id: ruleId,
				}],
			}],
			gatewayId: Gateway.selectedGatewayId!,
		} as const satisfies CmdGatewayActionGatewayDeleteRules;
		Glient.send(cmd, (error, msg) => {
			callback(error, msg);
		});
	}

	/**
	 * Updates the rule on the broadcast.
	 * @event rulesChanged
	 * @param {Object} msg
	 */
	public handleRulesBroadcast(msg: MsgBroadcastRules): void {
		const ruleIds: Array<RuleId> = [];
		for (const newRule of msg.payload.data) {
			ruleIds.push(newRule.id);
			const oldRule = this.getRuleById(newRule.id);
			if (oldRule) {
				Object.assign(oldRule, newRule); // update by reference
			} else {
				this.#rules.push(newRule);
			}
		}
		this.#rules.sort(Rules.#sortFunc);

		this.emit("rulesChanged", [...this.#rules], ruleIds, true);
	}

	/**
	 * Deletes the rule on broadcast
	 * @event rulesChanged
	 * @param {Object} msg
	 */
	public handleRulesDeletedBroadcast(msg: MsgBroadcastRulesDeleted): void {
		const ruleIds = msg.payload.data.map((rule) => (rule.id));
		this.#rules = this.#rules.filter((rule) => (!ruleIds.includes(rule.id)));

		this.emit("rulesChanged", [...this.#rules], ruleIds, false);
	}

}

export default (new Rules());
