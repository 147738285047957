// services
import { getClusterByClusterId, getClusterFromEndpoint, hasEndpointCapClusterId, hasEndpointClusterId } from "./device-helpers";
import DeviceType from "./device-type";
import EpDevice from "./ep-device";
import RocTable from "./roc-table";
import RuleCategory from "./rule-category";
import Constants from "./constants";
import ClusterConstants from "./cluster-constants";
// types
import type { ReadonlyDeep, SetRequired } from "type-fest";
import type { DeviceObj } from "../types/device";
import type { ClusterId } from "../types/cluster";
import type { RuleItemsType, AdvancedParamCategory } from "../types/rule";
import type {
	RocIdDeviceType, TemplateRulesTable, TemplateRulesParam,
	AdvancedRulesTable, AdvancedRulesTemplate, Filter, FilteredAdvancedRulesTableByType,
} from "../types/roc-table";

export const filterAndMapRuleTemplatesByTypeAndCategory = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(advancedRulesTable: AdvancedRulesTable, ruleItemsType: RIT, advancedParamCategory: APC) => (
	advancedRulesTable
		.filter((rulesTableEntry) => (
			rulesTableEntry.id.advancedCategory === advancedParamCategory && rulesTableEntry.data[ruleItemsType].length > 0
		))
		.map((rulesTableEntry) => ({
			...rulesTableEntry,
			data: rulesTableEntry.data[ruleItemsType],
		}))
);

const getRocIdFilterValue = (filter: SetRequired<ReadonlyDeep<Filter>, "rocId">, epDevice: EpDevice) => (
	filter.rocId.includes(epDevice.rocId)
);

const getDeviceTypeFilterValue = (filter: SetRequired<ReadonlyDeep<Filter>, "deviceType">, epDevice: EpDevice) => (
	filter.deviceType.includes(epDevice.getRocIdData("type"))
);

const getClusterFilterValue = (filter: SetRequired<ReadonlyDeep<Filter>, "cluster">, epDevice: EpDevice) => {
	const clusters = epDevice.ep[filter.clusterCaps ?? Constants.Caps.Incaps];
	return filter.cluster.some((excludeCluster) => {
		const { cluster_id: clusterId, ...clusterProps } = excludeCluster;
		const cluster = getClusterByClusterId(clusters, clusterId);
		if (cluster) {
			return Object.keys(clusterProps).every((key) => (cluster.hasOwnProperty(key) && cluster[key] === clusterProps[key]));
		} else {
			return false;
		}
	});
};

const getAttributeFilterValue = (filter: SetRequired<ReadonlyDeep<Filter>, "attribute">, epDevice: EpDevice) => (
	filter.attribute.some((attribute) => (
		Object.keys(attribute).every((key) => (epDevice.getAttribute(key) !== undefined && attribute[key] === epDevice.getAttribute(key)))
	))
);

const getCustomFilter = (epDevice: EpDevice) => {
	const FILTERS = [
		["rocId", getRocIdFilterValue],
		["deviceType", getDeviceTypeFilterValue],
		["cluster", getClusterFilterValue],
		["attribute", getAttributeFilterValue],
	] as const;

	return (config: ReadonlyDeep<TemplateRulesParam | AdvancedRulesTemplate>) => {
		if (config.filter) {
			let include = !config.filter.include;

			if (config.filter.include) {
				const included = FILTERS.some(([filterKey, funcFilterValue]) => (
					config.filter.include[filterKey] && funcFilterValue(config.filter.include, epDevice)
				));
				if (included) {
					include = true;
				}
			}

			if (include && config.filter.exclude) {
				const excluded = FILTERS.some(([filterKey, funcFilterValue]) => (
					config.filter.exclude[filterKey] && funcFilterValue(config.filter.exclude, epDevice)
				));
				if (excluded) {
					include = false;
				}
			}

			return include;
		} else {
			return true; // no filtering
		}
	};
};

export const getTemplateRulesFilterByEpDeviceParamClusterId = (epDevice: EpDevice, param: ReadonlyDeep<TemplateRulesParam>, clusterId: ClusterId | undefined) => {
	if (param.filter && !getCustomFilter(epDevice)(param)) {
		return false;
	}
	const deviceTypes = param.config.split(";") as Array<RocIdDeviceType>;
	if (!deviceTypes.includes(epDevice.getRocIdData("type"))) {
		return false;
	}
	if (clusterId !== undefined) {
		if (clusterId === DeviceType.DFF80.clusterId) {
			const clusterFF80 = getClusterFromEndpoint(epDevice.ep, DeviceType.DFF80.cap, DeviceType.DFF80.clusterId);
			if (clusterFF80?.hasOwnProperty(ClusterConstants.DFF80.Attributes.SetpointControllable) && !clusterFF80[ClusterConstants.DFF80.Attributes.SetpointControllable]) {
				return false;
			}
		} else {
			return hasEndpointClusterId(epDevice.ep, clusterId);
		}
	}
	return undefined;
};

export const getFilterTemplateRules = (templateRules: TemplateRulesTable, device: DeviceObj) => (
	templateRules.filter((templateRule) => (
		templateRule.data.params.filter((param) => (param.category === RuleCategory.device.id)).some((param) => {
			const clusterId = RocTable.getClusterId(param, templateRule.data.params);
			return device.eps.some((ep) => {
				const epDevice = EpDevice.getEpDeviceFromDeviceAndEpId(device, ep.endpoint);
				return getTemplateRulesFilterByEpDeviceParamClusterId(epDevice, param, clusterId) ?? false;
			});
		})
	))
);

const filterRuleTemplatesByClusterIdRequired = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(filteredRulesTable: FilteredAdvancedRulesTableByType<RIT, APC>, epDevice: EpDevice) => (
	filteredRulesTable.filter((rulesTableEntry) => (
		rulesTableEntry.id.clusterId && hasEndpointCapClusterId(epDevice.ep, rulesTableEntry.id.caps ?? Constants.Caps.Incaps, rulesTableEntry.id.clusterId)
	))
);

const filterRuleTemplatesByClusterIdOptional = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(filteredRulesTable: FilteredAdvancedRulesTableByType<RIT, APC>, epDevice: EpDevice) => (
	filteredRulesTable.filter((rulesTableEntry) => (
		!rulesTableEntry.id.clusterId || hasEndpointCapClusterId(epDevice.ep, rulesTableEntry.id.caps ?? Constants.Caps.Incaps, rulesTableEntry.id.clusterId)
	))
);

const filterRuleTemplatesByClusterIdMissing = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(filteredRulesTable: FilteredAdvancedRulesTableByType<RIT, APC>) => (
	filteredRulesTable.filter((rulesTableEntry) => (!rulesTableEntry.id.clusterId))
);

const filterRuleTemplatesByClusterId = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(filteredRulesTable: FilteredAdvancedRulesTableByType<RIT, APC>, epDevice: EpDevice) => {
	// epDevices with only 1 EP need always "device-rules" and "ep-rules"
	if (epDevice.eps.length === 1) {
		return filterRuleTemplatesByClusterIdOptional(filteredRulesTable, epDevice);
	}

	// Both "device-rules" and "ep-rules"
	if (epDevice.epDeviceType === (Constants.EpDeviceType.Device | Constants.EpDeviceType.Endpoint)) {
		return filterRuleTemplatesByClusterIdOptional(filteredRulesTable, epDevice);
	}
	// Only "device-rules"
	if (epDevice.epDeviceType === Constants.EpDeviceType.Device) {
		return filterRuleTemplatesByClusterIdMissing(filteredRulesTable);
	}
	// Only "ep-rules"
	if (epDevice.epDeviceType === Constants.EpDeviceType.Endpoint) {
		return filterRuleTemplatesByClusterIdRequired(filteredRulesTable, epDevice);
	}

	const hasEpDeviceValidCluster = filterRuleTemplatesByClusterIdRequired(filteredRulesTable, epDevice).length > 0;
	// If epDevice has valid cluster than return "device-rules" and "ep-rules"
	if (hasEpDeviceValidCluster) {
		return filterRuleTemplatesByClusterIdOptional(filteredRulesTable, epDevice);
	}

	return [];
};

const filterRuleTemplatesByTemplateFilter = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(filteredRulesTable: FilteredAdvancedRulesTableByType<RIT, APC>, epDevice: EpDevice) => (
	filteredRulesTable.map((rulesTableEntry) => ({
		id: rulesTableEntry.id,
		data: rulesTableEntry.data.filter(getCustomFilter(epDevice)),
	})).filter((rulesTableEntry) => (rulesTableEntry.data.length > 0))
);

const filterRuleTemplateForPhones = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(filteredRulesTable: FilteredAdvancedRulesTableByType<RIT, APC>) => (
	filteredRulesTable.map((rulesTableEntry) => ({
		...rulesTableEntry,
		data: rulesTableEntry.data.filter((rulesTableTemplate) => (
			rulesTableTemplate.params.some((param) => (param.ruleJson.attributeId === ClusterConstants.DFFAD.Attributes.GatewayReportAllPhonesAtHome && param.ruleJson.endpoint === "00"))
		)),
	}))
);

const filterRuleTemplateCheckThermostateState = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(rulesTableEntry: FilteredAdvancedRulesTableByType<RIT, APC>[number], epDevice: EpDevice/*, ruleItemsType: RIT*/) => {
	if (rulesTableEntry.id.clusterId === DeviceType.DFF80.clusterId) {
		const clusterFF80 = epDevice.getClusterByCapAndClusterId(DeviceType.DFF80.cap, DeviceType.DFF80.clusterId);
		if (clusterFF80) {
			const availableModes = Constants.ThermostatModes.filter((mode) => (Boolean(clusterFF80[ClusterConstants.DFF80.Attributes.AvailableModes] & mode.bitFlag)));
			return availableModes.length > 1;
		}
	}
	return true;
};

const filterRuleTemplatesBySpecialDeviceCases = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(filteredRulesTable: FilteredAdvancedRulesTableByType<RIT, APC>, epDevice: EpDevice/*, ruleItemsType: RIT*/) => (
	filteredRulesTable.map((rulesTableEntry) => ({
		...rulesTableEntry,
		data: rulesTableEntry.data.filter((rulesTableTemplate) => {
			switch (rulesTableTemplate.id) {
				case Constants.Rule.AdvancedRuleTemplateId.CheckThermostatState:
				case Constants.Rule.AdvancedRuleTemplateId.SetThermostatMode:
					return filterRuleTemplateCheckThermostateState(rulesTableEntry, epDevice/*, ruleItemsType*/);
				default:
					return true;
			}
		}),
	}))
);

export const filterAdvRuleTemplatesByEpDevice = <RIT extends RuleItemsType = RuleItemsType, APC extends AdvancedParamCategory = AdvancedParamCategory>(preFilteredRulesTable: FilteredAdvancedRulesTableByType<RIT, APC>, epDevice: EpDevice, ruleItemsType: RIT, advancedParamCategory: APC) => {
	const filteredRulesTable = filterRuleTemplatesByClusterId(preFilteredRulesTable, epDevice);
	const postFilteredRulesTable = filterRuleTemplatesByTemplateFilter(filteredRulesTable, epDevice);
	if (advancedParamCategory === Constants.Rule.AdvancedParamCategory.Device) {
		if (epDevice.id === epDevice.gwId.replaceAll(":", "")) {
			return filterRuleTemplateForPhones(postFilteredRulesTable);
		}
		return filterRuleTemplatesBySpecialDeviceCases(postFilteredRulesTable, epDevice/*, ruleItemsType*/);
	}
	return postFilteredRulesTable;
};
