// plugins
import { TuyaBridge } from "../plugins/TuyaBridge";
// services
import User from "./user";
import Glient from "./glient";
// types
import type { LoginOpts } from "../plugins/TuyaBridge/definitions";
import type { UserId } from "../types/user";
import type { CmdCloudActionTuya, PayloadResponseCloudActionTuyaUserCredentials } from "../types/message";

type PayloadResponseCloudActionTuyaUserCredentialsData = PayloadResponseCloudActionTuyaUserCredentials["data"];

const getTuyaUserCredentials = (userId: UserId) => (
	new Promise<PayloadResponseCloudActionTuyaUserCredentialsData>((resolve, reject) => {
		const cmd = {
			action: "cloudAction",
			module: "tuya",
			function: "get_user_credentials",
			params: [],
			sub: userId,
		} as const satisfies CmdCloudActionTuya;
		Glient.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				resolve((msg.payload as PayloadResponseCloudActionTuyaUserCredentials).data);
			} else {
				reject(error ?? new Error("Failed to get Tuya User Credentials"));
			}
		});
	})
);

const loginIntoTuya = async (tuyaUserCredentials: PayloadResponseCloudActionTuyaUserCredentialsData) => {
	const options = {
		countryCode: tuyaUserCredentials.country_code,
		username: tuyaUserCredentials.username,
		password: tuyaUserCredentials.password,
	} as const satisfies LoginOpts;
	await TuyaBridge.login(options);
};

export const initTuya = async (userId: UserId) => {
	if (User.channelInfo?.features.tuya?.enabled) {
		try {
			const tuyaUserCredentials = await getTuyaUserCredentials(userId);
			await loginIntoTuya(tuyaUserCredentials);
		} catch (error) {
			console.error("Could not login into tuya", error);
		}
	}
};

export const logoutTuya = async () => {
	if (User.channelInfo?.features.tuya?.enabled) {
		await TuyaBridge.logout();
	}
};
