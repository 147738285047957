// services
import Glient from "./glient";
// types
import type { ReadonlyDeep } from "type-fest";
import type { CmdGetOauthIntegrations, GetOauthIntegration } from "../types/message";

class OauthIntegrations {

	#oauthIntegrations: ReadonlyDeep<Array<GetOauthIntegration>> = [];

	public async fetch(): Promise<void> {
		return new Promise((resolve, reject) => {
			const cmd = {
				action: "getOauthIntegrations",
				type: "oauth2",
			} as const satisfies CmdGetOauthIntegrations;
			Glient.send(cmd, (error, msg) => {
				if (!error && msg?.payload.data) {
					this.#oauthIntegrations = msg.payload.data;
					resolve();
				} else {
					console.warn("Fetch available-integrations failed:", error);
					reject(error);
				}
			});
		});
	}

	public getById(id: GetOauthIntegration["id"]): GetOauthIntegration | undefined {
		return this.#oauthIntegrations.find((integration) => (integration.id === id));
	}

	public getByType(type: GetOauthIntegration["type"]): GetOauthIntegration | undefined {
		return this.#oauthIntegrations.find((integration) => (integration.type === type));
	}

}

export default new OauthIntegrations();
