import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
// plugins
import CustomUserDefaults from "../plugins/CustomUserDefaults";
import SecureStorage from "../plugins/SecureStorage";
import { NativeLogin } from "../plugins/NativeLogin";
// services
import { getServerOption, getGlientHttpUrlFromGlientWsUrl } from "./serverOption";
import { Storage, StorageKeys } from "./storage";
import Gateways from "./gateways";
import Gateway from "./gateway";
import Constants from "./constants";
import { defaultServerOptionId } from "./serverOption";
import { getSecureStoreName, setPhoneUUIDToStorage } from "./phoneuuid-helper";
import { config } from "@local/theme";
// types
import type { StorageKey, StorageValueWithDefault } from "./storage";
import type { GatewayId } from "../types/gateway";
import type { DeviceId, EndpointId, FavoriteDeviceId } from "../types/device";
import type { RoomId } from "../types/room";
import type { SceneId } from "../types/scenes";
import type { Currency, CookieWithUrl, JWT } from "../types/misc";

interface XamarinTokens {
	accessToken: JWT;
	refreshToken: JWT;
}

interface CookiesWithUrl {
	accessCookie: CookieWithUrl;
	refreshCookie: CookieWithUrl;
}

interface FavoriteDeviceXamarin {
	DeviceID: DeviceId;
	Endpoint: EndpointId | undefined;
}

const XAMARIN_IDENTIFIER = "ROCApp";

const PREFERENCE_KEYS_XAMARIN = {
	CURRENT_ENVIRONMENT: "current_environment",
	SELECTED_GATEWAY_ID: "selected_gateway_id",
	ENABLE_EXPERT_MODE: "enable_expert_mode",
	WELCOME_TEXT: "should_show_welcome_text",
	FAVOURITE_DEVICES: "favourite_devices",
	SELECTED_THEME: "selected_theme",
	SMART_WIDGETS: "smart_widgets",
	ROOMS: "rooms",
	LANDING_PAGE_CATEGORY_ORDER: "landing_page_category_order",
	FAVOURITE_SCENES: "favorite_scenes",
} as const;

let preKeyIdentifier = "";

// #region General

async function init() {
	if (Capacitor.getPlatform() === Constants.Platform.Android) { // if android, the group for UserDefaults is ROCApp, on iOS its the default folder, and the identifier is added before every key with Identifier_Key
		await CustomUserDefaults.configure({ group: XAMARIN_IDENTIFIER });
	} else {
		preKeyIdentifier = `${XAMARIN_IDENTIFIER}_`;
	}
}

// #region Tokens

function isValidBase64Json(data: string | undefined): boolean {
	if (data === undefined) {
		return false;
	}
	try {
		JSON.parse(atob(data));
		return true;
	} catch (error) {
		console.warn("invalid json", error, data);
		return false;
	}
}

async function getXamarinTokens(): Promise<XamarinTokens | undefined> {
	try {
		const storeName = await getSecureStoreName();
		const { data: accessToken } = await SecureStorage.get({ key: "accessToken", storename: storeName });
		const { data: refreshToken } = await SecureStorage.get({ key: "refreshToken", storename: storeName });

		if (accessToken === null || refreshToken === null) {
			console.info("getXamarinTokens: accessToken or refreshToken is not defined - skip migrate");
			return undefined;
		}
		if (accessToken === "" || refreshToken === "") {
			console.warn("getXamarinTokens: accessToken or refreshToken is empty string - skip migrate");
			return undefined;
		}
		const accessTokenParts = accessToken.split(".");
		if (accessTokenParts.length !== 3 || !isValidBase64Json(accessTokenParts[0]) || !isValidBase64Json(accessTokenParts[1]) || (accessTokenParts[2] ?? "").length === 0) {
			console.warn("getXamarinTokens: accessToken has no valid format - skip migrate");
			return undefined;
		}
		const refreshTokenParts = refreshToken.split(".");
		if (refreshTokenParts.length !== 3 || !isValidBase64Json(refreshTokenParts[0]) || !isValidBase64Json(refreshTokenParts[1]) || (refreshTokenParts[2] ?? "").length === 0) {
			console.warn("getXamarinTokens: refreshToken has no valid format - skip migrate");
			return undefined;
		}

		await SecureStorage.delete({ key: "accessToken", storename: storeName });
		await SecureStorage.delete({ key: "refreshToken", storename: storeName });

		return {
			accessToken: accessToken as JWT,
			refreshToken: refreshToken as JWT,
		} as const satisfies XamarinTokens;
	} catch (error) {
		console.error("error in getXamarinTokens", error);
		return undefined;
	}
}

function createCapacitorTokens(xamarinTokens: XamarinTokens): CookiesWithUrl {
	const { channel, accessCookieConfig, refreshCookieConfig, redirectUrl } = getServerOption();

	const params = new URLSearchParams({
		state: globalThis.crypto.randomUUID(),
		channel: channel,
	});

	const accessCookie = {
		...accessCookieConfig,
		value: xamarinTokens.accessToken,
		url: getGlientHttpUrlFromGlientWsUrl(),
	} as const satisfies CookieWithUrl;

	const refreshCookie = {
		...refreshCookieConfig,
		value: xamarinTokens.refreshToken,
		url: `${redirectUrl}?${params.toString()}`,
	} as const satisfies CookieWithUrl;

	return {
		accessCookie: accessCookie,
		refreshCookie: refreshCookie,
	} as const satisfies CookiesWithUrl;
}

async function migrateTokens() {
	try {
		const xamarinTokens = await getXamarinTokens();
		if (xamarinTokens === undefined) {
			return;
		}

		const capacitorCookies = createCapacitorTokens(xamarinTokens);
		await NativeLogin.migrateTokens(capacitorCookies);
	} catch (error) {
		console.error(error); // TODO: send to backend via new feedback api
	}
}

// #endregion

// #region DataMigration

function writeStorageWithParsing(newKey: StorageKey, data: string) {
	if (data.length > 0) {
		try {
			const parsedData = JSON.parse(data);
			Storage.set(newKey, parsedData);
		} catch (error) {
			console.error(error);
		}
	}
}

function migrateQuickViewSettingsSectionsOrder(key: string, data: string) {
	const ORDER_MAPPING = {
		0: "favoriteDevices",
		1: "smartWidgets",
		2: "favoriteScenes",
	} as const;

	const gatewayId = key.substring(key.lastIndexOf("_") + 1) as GatewayId;
	const newKey = StorageKeys.quickviewSettingsSectionsOrder.replace("{GWID}", gatewayId) as StorageKey;

	const parsed = JSON.parse(data);
	const newVal: Array<(typeof Constants.QuickviewSettingsDefaultSectionsOrder)[number]> = [];
	for (const entry of parsed.LandingpageOrder) {
		const value = ORDER_MAPPING[entry];
		if (value) {
			newVal.push(value);
		} else {
			Storage.set(newKey, Constants.QuickviewSettingsDefaultSectionsOrder);
			return;
		}
	}
	Storage.set(newKey, newVal);
}

function migrateFavoriteDevices(key: string, data: string) {
	const gatewayId = key.substring(key.lastIndexOf("_") + 1) as GatewayId;
	const newKey = StorageKeys.favoriteDeviceIds.replace("{GWID}", gatewayId) as StorageKey;

	const parsed = JSON.parse(data);

	const newVal = (parsed as Array<FavoriteDeviceXamarin>).map((item) => ({
		id: item.DeviceID,
		epId: item.Endpoint ?? null,
	} as const satisfies FavoriteDeviceId));

	Storage.set(newKey, newVal);
}

function migrateFavoriteScenes(key: string, data: string) {
	const gatewayId = key.substring(key.lastIndexOf("_") + 1) as GatewayId;
	const newKey = StorageKeys.sceneIds.replace("{GWID}", gatewayId) as StorageKey;
	const parsed = JSON.parse(data);
	const newVal: Array<SceneId> = [];
	for (const item of parsed) {
		newVal.push(item.Id);
	}
	Storage.set(newKey, newVal);
}

/**
	* supportedCurrencies: ["EUR", "CZK", "RON", "SEK", "CHF"],
	* xam currencies:
	* public const String currency_euro = "€";
	* public const String currency_czech_koruna = "kč";
	* public const String currency_romanian_leu = "lei";
	* public const String currency_swedish_krona = "kr";
	* public const String currency_swiss_franc = "CHF";
	*/
function migrateCurrencyUnit(key: string, data: string) {
	const replacedKey = key.replace(preKeyIdentifier, "");
	const gatewayId = replacedKey.substring(0, replacedKey.indexOf("_")) as GatewayId;
	const newKey = StorageKeys.electricityUnitPriceCurrency.replace("{GWID}", gatewayId) as StorageKey;
	const parsed = JSON.parse(data);
	const MAPPED_CURRENCIES = {
		"€": "EUR",
		"kč": "CZK",
		"lei": "RON",
		"kr": "SEK",
		"CHF": "CHF",
	} as const satisfies Record<string, Currency>;
	const newVal = MAPPED_CURRENCIES[parsed] ?? config.defaultCurrency;
	Storage.set(newKey, newVal);
}

/**
	* favoriteRoomDevicesOrder
	* Capacitor: /@roc/roc-web-app/favoriteRoomDevicesOrder/3C:62:F0:09:AA:0B/a1ec2f33843b02eda263e84a3c2bbd6d
	* ["EC1BBDFFFEB62DAD","E479C1940109"]
	* xamarin: $"{Constants.Storage.ROOMS}_{roomId}_{gatewayId}"
	*/
function migrateFavoriteRoomDevicesOrder(key: string, data: string) {
	const gatewayIndex = key.lastIndexOf("_") + 1;
	const gatewayId = key.substring(gatewayIndex) as GatewayId;
	const sub = key.substring(0, gatewayIndex - 1);
	const roomId = sub.substring(sub.lastIndexOf("_") + 1) as RoomId;
	const newKey = StorageKeys.favoriteRoomDevicesOrder.replace("{GWID}", gatewayId).replace("{ROOM_ID}", roomId) as StorageKey;
	writeStorageWithParsing(newKey, data);
}

/**
	* favoriteRoomsOrder
	* capacitor: favoriteRoomsOrder: "favoriteRoomsOrder/{GWID}",
	* ["9bb8265920460ce3afb9b7d63d61c03d","a1ec2f33843b02eda263e84a3c2bbd6d"]
	* xamarin: $"{Constants.Storage.ROOMS}_{gatewayId}"
	*/
function migrateFavoriteRoomsOrder(key: string, data: string) {
	const gatewayId = key.substring(key.lastIndexOf("_") + 1) as GatewayId;
	const newKey = StorageKeys.favoriteRoomsOrder.replace("{GWID}", gatewayId) as StorageKey;
	writeStorageWithParsing(newKey, data);
}

function migrateFavoriteRooms(key: string, data: string) {
	// check is rooms or rooms_roomid
	const CHECK_COUNT_UNDERSCORES_FAVORITE_ROOM_DEVICES_ORDER = 2;
	const replacedKey = key.replace(preKeyIdentifier, "");
	const underscoreCount = (replacedKey.match(/_/gi) ?? []).length;
	if (underscoreCount === CHECK_COUNT_UNDERSCORES_FAVORITE_ROOM_DEVICES_ORDER) {
		migrateFavoriteRoomDevicesOrder(replacedKey, data);
	} else {
		migrateFavoriteRoomsOrder(replacedKey, data);
	}
}

function migrateSmartWidgets(key: string, data: string) {
	const gatewayId = key.substring(key.lastIndexOf("_") + 1) as GatewayId;
	const newKey = StorageKeys.smartWidgetIds.replace("{GWID}", gatewayId) as StorageKey;
	writeStorageWithParsing(newKey, data);
}

// add missed mappings here / define mappings / tuya stuff
async function writeStorageForKey(key: string, value: string): Promise<Readonly<[string, string]> | undefined> {
	if (key === `${preKeyIdentifier}${PREFERENCE_KEYS_XAMARIN.CURRENT_ENVIRONMENT}`) {
		if (value.length > 0) {
			const XAMARIN_SERVER_ID_MAPPING = {
				dev: "dev",
				dev25: "dev",
				alpha: "alpha",
				alpha25: "alpha",
				shhb: "prod",
				sodimac: "prod",
			} as const;
			Storage.set(StorageKeys.selectedBackendServer, XAMARIN_SERVER_ID_MAPPING[value] ?? defaultServerOptionId as StorageValueWithDefault<"selectedBackendServer">);
		}
		return undefined;
	}

	if (key === `${preKeyIdentifier}${PREFERENCE_KEYS_XAMARIN.SELECTED_GATEWAY_ID}`) {
		window.localStorage.setItem("tempSelectedGateway", value);
		return undefined;
	}

	if (key.includes(`${preKeyIdentifier}${PREFERENCE_KEYS_XAMARIN.WELCOME_TEXT}`)) {
		const gatewayId = key.substring(key.lastIndexOf("_") + 1) as GatewayId;
		const newKey = StorageKeys.welcomeTextEnabled.replace("{GWID}", gatewayId) as StorageKey;
		writeStorageWithParsing(newKey, value);
		return undefined;
	}

	if (key.includes(`${preKeyIdentifier}${PREFERENCE_KEYS_XAMARIN.SELECTED_THEME}`)) {
		if (value.length > 0) {
			// Mapping as per Xamarin APP [System, ROCTheme, HornbachTheme, OzomTheme, ROCDarkTheme, HornbachDarkTheme, OzomDarkTheme]
			const theme = {
				0: Constants.Appearance.System,
				1: Constants.Appearance.Light,
				2: Constants.Appearance.Light,
				3: Constants.Appearance.Light,
				4: Constants.Appearance.Dark,
				5: Constants.Appearance.Dark,
				6: Constants.Appearance.Dark,
			} as const;
			Storage.set(StorageKeys.appearance, theme[value] ?? Constants.Appearance.System);
		}
		return undefined;
	}

	if (key.includes(`${preKeyIdentifier}${PREFERENCE_KEYS_XAMARIN.FAVOURITE_DEVICES}`)) {
		migrateFavoriteDevices(key, value);
		return undefined;
	}

	if (key.includes(`${preKeyIdentifier}${PREFERENCE_KEYS_XAMARIN.FAVOURITE_SCENES}`)) {
		migrateFavoriteScenes(key, value);
		return undefined;
	}

	if (key.includes("_electricity_cost")) {
		const replacedKey = key.replace(preKeyIdentifier, "");
		const gatewayId = replacedKey.substring(0, replacedKey.indexOf("_")) as GatewayId;
		const newKey = StorageKeys.electricityUnitPriceValue.replace("{GWID}", gatewayId) as StorageKey;
		writeStorageWithParsing(newKey, value);
		return undefined;
	}

	if (key.includes("_currency_unit")) {
		migrateCurrencyUnit(key, value);
		return undefined;
	}

	// smartwidgets
	if (key.includes(`${preKeyIdentifier}${PREFERENCE_KEYS_XAMARIN.SMART_WIDGETS}`)) {
		migrateSmartWidgets(key, value);
		return undefined;
	}

	if (key.includes(`${preKeyIdentifier}${PREFERENCE_KEYS_XAMARIN.LANDING_PAGE_CATEGORY_ORDER}`)) {
		migrateQuickViewSettingsSectionsOrder(key, value);
		return undefined;
	}

	if (key.includes(`${preKeyIdentifier}${PREFERENCE_KEYS_XAMARIN.ROOMS}`)) {
		migrateFavoriteRooms(key, value);
		return undefined;
	}

	// remove data that will not migrated
	const storeName = await getSecureStoreName();
	await SecureStorage.delete({ key: key, storename: storeName });

	return [key, value] as const;
}

async function migrateSimpleStorage(): Promise<void> {
	const notMigrated: Array<Readonly<[string, string]>> = [];

	const { keys } = await CustomUserDefaults.getKeys();
	for (const key of keys) {
		const { value } = await CustomUserDefaults.get({ key: key });
		try {
			const result = await writeStorageForKey(key, value ?? "");
			if (result) {
				notMigrated.push(result);
			}
		} catch (error) {
			console.error("Failed to migrate entry:", key, value, error);
		}
	}

	if (notMigrated.length > 0) {
		console.info("NOT MAPPED XAMARIN MIGRATION DATA:", notMigrated);
	}

	await CustomUserDefaults.clear();
}

async function migratePhoneId() {
	const storeName = await getSecureStoreName();
	const { data } = await SecureStorage.get({ key: "PHONE_ID", storename: storeName });
	if (data) {
		await setPhoneUUIDToStorage(data);
	}
}

// #endregion

// #region Public Functions / exports

export async function migrateNativeDataFromXamarin(): Promise<void> {
	const { value } = await Preferences.get({ key: "isMigrated" });
	if (value === "true") {
		return;
	}

	try {
		await init();
		await migrateSimpleStorage();
		await migrateTokens();
		await migratePhoneId();
	} catch (error) {
		console.error("migration failed", error);
		await CustomUserDefaults.clear();
	}

	await Preferences.set({ key: "isMigrated", value: "true" });
}

export function migrateSelectedGateway(): void {
	const selectedGatewayId = window.localStorage.getItem("tempSelectedGateway");
	if (selectedGatewayId === null) {
		return;
	}

	const selectedGateway = Gateways.getGateways().find((gateway) => (gateway.id === selectedGatewayId));
	if (selectedGateway) {
		Gateway.selectGateway(selectedGateway);
	}
	window.localStorage.removeItem("tempSelectedGateway");
}

// #endregion
