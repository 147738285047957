// services
import { Storage, StorageKeys } from "./storage";
import { config } from "@local/theme";
// types
import type { ServerId } from "../types/config";
import type { UrlHTTP } from "../types/misc";

export const defaultServerOptionId: ServerId = process.env.SERVER_ID as ServerId | undefined ?? ((process.env.BUILD_MODE === "production") ? "prod" as const : "dev" as const);

export const getServerOption = () => {
	const selectedBackendServer = Storage.get(StorageKeys.selectedBackendServer);

	return config.serverOptions.find((serverOption) => (serverOption.id === selectedBackendServer))!;
};

export const getGlientHttpUrlFromGlientWsUrl = () => {
	const { glientWsUrl } = getServerOption();

	return glientWsUrl.replace(/^ws/, "http") as UrlHTTP;
};
