import deepmerge from "deepmerge";
import Constants from "./constants";
// types
import type { MergeDeep } from "type-fest";
import type { RocIdDeviceType } from "../types/roc-table";
import type { ClusterId } from "../types/cluster";

type ValuePlaceholderArray = Array<ValuePlaceholderOld>;
type ValuePlaceholderObject = { [key: string]: ValuePlaceholderOld }; // eslint-disable-line @typescript-eslint/consistent-indexed-object-style
type ValuePlaceholderOld = ValuePlaceholderNew | ValuePlaceholderArray | ValuePlaceholderObject;
type ValuePlaceholderNew = string | number | boolean;
type OrIsValueArray<V> = Array<"or" | readonly ["is", V]>;
type Duration = Readonly<{
	hours: number;
	minutes: number;
	seconds: number;
}>;

export const mergeDeep = <O1, O2>(obj1: O1, obj2: O2): MergeDeep<O1, O2> => (
	deepmerge<O1, O2>(obj1, obj2, { arrayMerge: (_destinationArray, sourceArray) => (sourceArray) }) as MergeDeep<O1, O2>
);

export const roundToPrecision = (number: number, precision: number = 0.1): number => (
	Math.round(number / precision) / (1 / precision) // Is a hack for correct rounding of: Math.round(number / precision) * precision
);

export const convertToF = (celsius: number, precision: number = 0.1): number => (
	roundToPrecision(celsius * 1.8 + 32, precision)
);

export const convertToC = (fahrenheit: number, precision: number = 0.1): number => (
	roundToPrecision((fahrenheit - 32) / 1.8, precision)
);

export const decimal2Hex = (decimal: number, length: number | undefined = undefined, signed: boolean = false): string => {
	const value = (typeof length === "number" && (signed || decimal < 0)) ? decimal & (Math.pow(16, length) - 1) : decimal;
	const hex = value.toString(16).toUpperCase();
	if (length === undefined) {
		return hex;
	}
	return hex.padStart(length, "0");
};

export const hex2Decimal = (hex: string, signed: boolean = false): number => {
	const maximum = Math.pow(16, hex.length);
	const value = Number.parseInt(hex, 16);
	return (signed && value > (maximum - 1) / 2) ? -(value ^ (maximum - 1)) - 1 : value;
};

export const getNumberRange = (min: number, max: number, step: number = 1): Array<number> => {
	const numberRange: Array<number> = [];
	// We have to round here because of how JavaScript handles decimal values. (0.2 + 0.1 = 0.30000000000000004)
	for (let i = min; i <= max; i = roundToPrecision(i + step, step)) {
		numberRange.push(i);
	}
	return numberRange;
};

export const minMax = (value: number, min: number = 0, max: number = 255): number => (
	Math.min(Math.max(value, min), max)
);

export const isValidEmail = (email: string): boolean => {
	const regex = /^(?:(?:[^<>()[\]\\.,;:\s@"]+(?:\.[^<>()[\]\\.,;:\s@"]+)*)|(?:".+"))@(?:(?:\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(?:(?:[a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(email);
};

export const replaceValuePlaceholder = (oldValue: ValuePlaceholderOld, newValue: ValuePlaceholderNew, search: string = "{VALUE}"): ValuePlaceholderOld => {
	if (typeof oldValue === "string") {
		if (oldValue === search) {
			return newValue;
		}
		if (typeof newValue === "string" && oldValue.includes(search)) {
			return oldValue.replaceAll(new RegExp(search, "g"), newValue);
		}
	}
	if (Array.isArray(oldValue)) {
		return oldValue.map((item) => (replaceValuePlaceholder(item, newValue, search)));
	}
	if (typeof oldValue === "object") {
		for (const key in oldValue) {
			if (oldValue.hasOwnProperty(key)) {
				oldValue[key] = replaceValuePlaceholder(oldValue[key], newValue, search);
			}
		}
		return oldValue;
	}
	return oldValue;
};

export const mapOrArrayValues = <V>(newValues: Readonly<Array<V>>): OrIsValueArray<V> => (
	newValues.reduce<OrIsValueArray<V>>((accumulator, currentValue): OrIsValueArray<V> => (
		(accumulator.length === 0) ? [["is", currentValue]] : [...accumulator, "or", ["is", currentValue]]
	), [])
);

export const getTimePartsFromDuration = (duration: number): Duration => {
	const ms = duration % 1000;
	const s = (duration - ms) / 1000;
	const seconds = s % 60;
	const m = (s - seconds) / 60;
	const minutes = m % 60;
	const hours = (m - minutes) / 60;

	return {
		hours: hours,
		minutes: minutes,
		seconds: seconds,
	} as const;
};

export const getClusterIdOrder = <CIDS extends ReadonlyArray<ClusterId>>(clusterIdFilterList: CIDS): ReadonlyArray<CIDS[number]> => (
	Constants.ClusterIds.OrderList.filter((clusterId) => (clusterIdFilterList.includes(clusterId)))
);

const getRandomUintArray = (length: number): Uint8Array => {
	const uintArray = new Uint8Array(length);
	globalThis.crypto.getRandomValues(uintArray);
	return uintArray;
};

export const getRandomNumberString = (oneToFive: boolean = false, length: number = 7): string => {
	const uintArray = getRandomUintArray(length);
	const mapFunction = oneToFive ? ((value: number) => (Math.floor(value * 4.99 / 255) + 1)) : ((value: number) => (Math.floor(value * 9.99 / 255)));
	return Array.from(uintArray, mapFunction).join("");
};

export const buildTemplateRegex = (param: string): RegExp => (
	new RegExp(`{${param};([^}]+)}`)
);

export const hash = async (message: string, algorithm: AlgorithmIdentifier = "SHA-256") => {
	const msgUint8 = new TextEncoder().encode(message);
	const hashBuffer = await crypto.subtle.digest(algorithm, msgUint8);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray.map((b) => (b.toString(16).padStart(2, "0"))).join("");
	return hashHex;
};

export const isRocBuildUrl = () => (window.location.origin === "https://rocbuild.blob.core.windows.net");

export const getRefererUrl = () => (`${window.location.origin}${window.location.pathname}${window.location.search}`);

export const ROTATE_DEVICE_TYPE = [
	"dt_shades_controller",
	"dt_motion_shades_controller",
	"dt_group_shades",
] as const satisfies ReadonlyArray<RocIdDeviceType>;

export const OFFLINE_INTEGRATIONS = [
	Constants.PairingDeviceCategory.Hue,
	Constants.PairingDeviceCategory.Motion,
	Constants.PairingDeviceCategory.Nuki,
] as const;

export const C2C_INTEGRATIONS = [
	Constants.PairingDeviceCategory.Eglo,
	Constants.PairingDeviceCategory.Comet,
	Constants.PairingDeviceCategory.Coulisse,
	Constants.PairingDeviceCategory.Everhome,
	Constants.PairingDeviceCategory.Ezviz,
	Constants.PairingDeviceCategory.Gardena,
	Constants.PairingDeviceCategory.HomeConnect,
	Constants.PairingDeviceCategory.HueRemote,
	Constants.PairingDeviceCategory.Indego,
	Constants.PairingDeviceCategory.Mystrom,
	Constants.PairingDeviceCategory.Netatmo,
	Constants.PairingDeviceCategory.Ondilo,
	Constants.PairingDeviceCategory.Shelly,
	Constants.PairingDeviceCategory.Tado,
	Constants.PairingDeviceCategory.Tuya,
	Constants.PairingDeviceCategory.Wiz,
	Constants.PairingDeviceCategory.Yale,
] as const;

// pairingDevice categories that are not C2C or "alexa" or "ghome"
export const PAIRING_DEVICE_CATEGORIES_WITH_HINT = [
	Constants.PairingDeviceCategory.Device,
	Constants.PairingDeviceCategory.Hue,
	Constants.PairingDeviceCategory.Motion,
	Constants.PairingDeviceCategory.Nuki,
	Constants.PairingDeviceCategory.Wifi,
	Constants.PairingDeviceCategory.Zigbee,
	Constants.PairingDeviceCategory.Zwave,
] as const;

// mapped deviceType list of C2C_INTEGRATIONS
export const C2C_DEVICE_TYPES = [
	Constants.Device.Attributes.Value.DeviceType.Eglo,
	Constants.Device.Attributes.Value.DeviceType.Comet,
	Constants.Device.Attributes.Value.DeviceType.Coulisse,
	Constants.Device.Attributes.Value.DeviceType.Everhome,
	Constants.Device.Attributes.Value.DeviceType.Ezviz,
	Constants.Device.Attributes.Value.DeviceType.Gardena,
	Constants.Device.Attributes.Value.DeviceType.HomeConnect,
	Constants.Device.Attributes.Value.DeviceType.Hue,
	Constants.Device.Attributes.Value.DeviceType.HueRemote,
	Constants.Device.Attributes.Value.DeviceType.Indego,
	Constants.Device.Attributes.Value.DeviceType.MotionActions,
	Constants.Device.Attributes.Value.DeviceType.Mystrom,
	Constants.Device.Attributes.Value.DeviceType.Netatmo,
	Constants.Device.Attributes.Value.DeviceType.NukiActions,
	Constants.Device.Attributes.Value.DeviceType.Ondilo,
	Constants.Device.Attributes.Value.DeviceType.Shelly,
	Constants.Device.Attributes.Value.DeviceType.Tado,
	Constants.Device.Attributes.Value.DeviceType.Wiz,
	Constants.Device.Attributes.Value.DeviceType.Yale,
] as const;

export const C2C_DEVICE_TYPES_RENAME_HINT = [
	Constants.Device.Attributes.Value.DeviceType.Gardena,
	Constants.Device.Attributes.Value.DeviceType.HueRemote,
	Constants.Device.Attributes.Value.DeviceType.Indego,
	Constants.Device.Attributes.Value.DeviceType.Tado,
	Constants.Device.Attributes.Value.DeviceType.Wiz,
] as const;

export const DEVICE_TYPE_L10N_KEYS = {
	eglo: "pairingDevices.pn_eglo_crosslink",
	comet: "pairingDevices.pn_eurotronic_comet_wifi",
	coulisse: "pairingDevices.pn_motion_blinds",
	everhome: "pairingDevices.pn_everhome_ecotracker",
	ezviz: "pairingDevices.pn_ezviz_camera",
	gardena: "pairingDevices.pn_gardena_smart_system",
	home_connect: "pairingDevices.pn_home_connect",
	hue: "pairingDevices.pn_philips_hue_bridge_bsb002",
	hue_remote: "pairingDevices.pn_philips_hue_remote",
	indego: "pairingDevices.pn_bosch_indego",
	motionActions: "pairingDevices.pn_motion_bridge",
	mystrom: "pairingDevices.pn_mystrom_wifi_plug",
	netatmo: "pairingDevices.pn_netatmo_camera",
	nukiActions: "pairingDevices.pn_nuki_bridge",
	ondilo: "pairingDevices.pn_ondilo_pool_sensor",
	shelly: "pairingDevices.pn_shelly",
	tado: "pairingDevices.pn_tado",
	wiz: "pairingDevices.pn_wiz_connected",
	yale: "pairingDevices.pn_yale",
} as const;

export const INTEGRATIONS_L10N_KEYS = {
	eglo: "pairingDevices.pn_eglo_crosslink",
	comet: "pairingDevices.pn_eurotronic_comet_wifi",
	coulisse: "pairingDevices.pn_motion_blinds",
	everhome: "pairingDevices.pn_everhome_ecotracker",
	ezviz: "pairingDevices.pn_ezviz_camera",
	gardena: "pairingDevices.pn_gardena_smart_system",
	home_connect: "pairingDevices.pn_home_connect",
	hue: "pairingDevices.pn_philips_hue_bridge_bsb002",
	hue_remote: "pairingDevices.pn_philips_hue_remote",
	indego: "pairingDevices.pn_bosch_indego",
	motion: "pairingDevices.pn_motion_bridge",
	mystrom: "pairingDevices.pn_mystrom_wifi_plug",
	netatmo: "pairingDevices.pn_netatmo_camera",
	nuki: "pairingDevices.pn_nuki_bridge",
	ondilo: "pairingDevices.pn_ondilo_pool_sensor",
	shelly: "pairingDevices.pn_shelly",
	tado: "pairingDevices.pn_tado",
	tuya: "pairingDevices.pn_tuya",
	wiz: "pairingDevices.pn_wiz_connected",
	yale: "pairingDevices.pn_yale",
} as const;

export const START_SWITCH_OPERATION_STATE_ENABLED = [
	Constants.HomeConnect.OperationState.Ready,
	Constants.HomeConnect.OperationState.Pause,
	Constants.HomeConnect.OperationState.Finished,
] as const;

export const STOP_SWITCH_OPERATION_STATE_ENABLED = [
	Constants.HomeConnect.OperationState.DelayedStart,
	Constants.HomeConnect.OperationState.Run,
] as const;

export const BBCODE_BOLD_REGEX = /\[b](.+?)\[\/b]/;
