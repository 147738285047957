// cmp
import ReactMultipleStringReplace from "../cmp/ReactMultipleStringReplace";
import ColorCircle from "../cmp/color-circle";
// services
import i18n from "./i18n";
import Gateway from "./gateway";
import Scenes from "./scenes";
import Constants from "./constants";
import ClusterConstants from "./cluster-constants";
import { LOCALE, numberFormatter0Digits, getWeekDayL10n } from "./l10n";
import { getColorPrimariesFromCluster, convertColorXYToColorRGB, convertColorRGBToColorXY, rgbToHex, convertMiredToHex } from "./color";
import { roundToPrecision, convertToF, decimal2Hex, hex2Decimal, getTimePartsFromDuration } from "./utils";
// types
import type { ReactElement, JSX } from "react";
import type { FullName, EpDevice } from "../types/device";
import type { IncapsCluster0300, IncapsCluster0702, IncapsClusterFF92 } from "../types/cluster";
import type { SceneId } from "../types/scenes";
import type { ColorHex, ColorRGB, ColorXYString, SmartMode, SmartModes, Days } from "../types/misc";

const numberFormatter = new Intl.NumberFormat(LOCALE.language).format;

/*
 * Type of rule template params.
 * rules categories: ["co", "co2", "device", "duration", "humidity", "illuminance", "mode", "predefined", "setpoint", "temperature", "voc"]
 * adv-rules categories: ["air_pressure", "co", "co2", "color", "color_temperature", "device", "dim_level", "curtain_position", "dow", "duration", "humidity", "illuminance", "mode", "modes", "nuki_state", "predefined", "power_consumption", "setpoint", "temperature", "time", "voc"]
 */

const RuleCategory = {
	device: {
		id: "device",
		printValue: (value: EpDevice): FullName => (value.fullName), // inactive
		decodeValue: (value: EpDevice): EpDevice => (value), // inactive
		encodeValue: (value: EpDevice): EpDevice => (value), // inactive
	},
	scene: {
		id: "scene",
		printValue: (value: SceneId): string => {
			const scene = Scenes.getSceneById(value);
			return scene?.name ?? "";
		},
		decodeValue: (value: SceneId): SceneId => (value),
		encodeValue: (value: SceneId): SceneId => (value),
	},
	airPressure: {
		id: "air_pressure",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.HectoPascal}`),
		decodeValue: (value: number): number => (value),
		encodeValue: (value: number): number => (value),
	},
	duration: {
		id: "duration",
		printValue: (value: number): ReactElement => {
			const { hours, minutes } = getTimePartsFromDuration(value);
			const h = (hours === 0) ? "" : `${hours} ${i18n.t("general.timer.timerHours", { count: hours })} `;
			const m = `${minutes} ${i18n.t("general.timer.timerMinutes", { count: minutes })}`;
			return <time dateTime={`PT${hours}H${minutes}M`}>{`${h}${m}`}</time>;
		},
		decodeValue: (value: string | number, output: string | null = null): number => (
			(output === "sendActionCmd") ? hex2Decimal(value as string) * 100 : value as number
		),
		encodeValue: (value: number, output: string | null = null): string | number => (
			(output === "sendActionCmd") ? decimal2Hex(value / 100, 4) : value
		),
	},
	temperature: {
		id: "temperature",
		printValue: (value: number): string => {
			const gatewayTempUnit = Gateway.selectedGatewayTemperature;
			const tempValue = (gatewayTempUnit === Constants.TempUnit.Fahrenheit) ? convertToF(value) : roundToPrecision(value);
			return `${numberFormatter(tempValue)}${Constants.Units.Degree}${gatewayTempUnit}`;
		},
		decodeValue: (value: number): number => (value / 100),
		encodeValue: (value: number): number => (value * 100),
	},
	ph: {
		id: "ph",
		printValue: (value: number): string => (numberFormatter(value)),
		decodeValue: (value: number): number => (value / 100),
		encodeValue: (value: number): number => (value * 100),
	},
	saltConcentration: {
		id: "salt_concentration",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.SaltCocentration}`),
		decodeValue: (value: number): number => (value / 100),
		encodeValue: (value: number): number => (value * 100),
	},
	oydoReductionPotential: {
		id: "orp",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.OydoReductionPotential}`),
		decodeValue: (value: number): number => (value / 100),
		encodeValue: (value: number): number => (value * 100),
	},
	totalDissolvedSolids: {
		id: "tds",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.PartsPerMillion}`),
		decodeValue: (value: number): number => (value / 100),
		encodeValue: (value: number): number => (value * 100),
	},
	checkSetpoint: {
		id: "check_setpoint",
		printValue: (value: number): string => {
			const gatewayTempUnit = Gateway.selectedGatewayTemperature;
			const tempValue = (gatewayTempUnit === Constants.TempUnit.Fahrenheit) ? convertToF(value, 0.5) : roundToPrecision(value);
			return `${numberFormatter(tempValue)}${Constants.Units.Degree}${gatewayTempUnit}`;
		},
		decodeValue: (value: number): number => (value / 100),
		encodeValue: (value: number): number => (Math.round(value * 100)),
	},
	setpoint: {
		id: "setpoint",
		printValue: (value: number): string => {
			const gatewayTempUnit = Gateway.selectedGatewayTemperature;
			const tempValue = (gatewayTempUnit === Constants.TempUnit.Fahrenheit) ? convertToF(value, 0.5) : roundToPrecision(value);
			return `${numberFormatter(tempValue)}${Constants.Units.Degree}${gatewayTempUnit}`;
		},
		decodeValue: (value: string): number => (hex2Decimal(value) / 100),
		encodeValue: (value: number): string => (decimal2Hex(Math.round(value * 100), 4)),
	},
	illuminance: {
		id: "illuminance",
		printValue: (value: number): string => (`${numberFormatter0Digits(value)} ${Constants.Units.Lux}`),
		decodeValue: (value: number): number => {
			const newValue = Math.pow(10, value / 10000) - 1;
			// see illuminance-component (this.ILLUMINANCE_STEPS_FROM)
			if (newValue < 10) {
				return roundToPrecision(newValue, 2);
			}
			if (newValue < 50) {
				return roundToPrecision(newValue, 10);
			}
			return roundToPrecision(newValue, 50);
		},
		encodeValue: (value: number): number => (Math.round(10000 * Math.log10(value + 1))),
	},
	humidity: {
		id: "humidity",
		printValue: (value: number): string => (`${numberFormatter(value)}${Constants.Units.Percent}`),
		decodeValue: (value: number): number => (Math.round(value / 100)),
		encodeValue: (value: number): number => (value * 100),
	},
	co: {
		id: "co",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.PartsPerMillion}`),
		decodeValue: (value: number): number => (Math.round(value / 100)),
		encodeValue: (value: number): number => (value * 100),
	},
	co2: {
		id: "co2",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.PartsPerMillion}`),
		decodeValue: (value: number): number => (Math.round(value / 100)),
		encodeValue: (value: number): number => (value * 100),
	},
	voc: {
		id: "voc",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.PartsPerBillion}`),
		decodeValue: (value: number): number => (Math.round(value * 1000 / 100)),
		encodeValue: (value: number): number => (value * 100 / 1000),
	},
	smartMode: {
		id: "smart_mode",
		printValue: (value: SmartMode): string => (i18n.t(`welcome.gateway.modes.${value}`)),
		decodeValue: (value: SmartMode): SmartMode => (value),
		encodeValue: (value: SmartMode): SmartMode => (value),
	},
	mode: {
		id: "mode",
		printValue: (value: SmartMode): string => (i18n.t(`welcome.gateway.modes.${value}`)),
		decodeValue: (value: SmartMode): SmartMode => (value),
		encodeValue: (value: SmartMode): SmartMode => (value),
	},
	modes: {
		id: "modes",
		printValue: (value: SmartModes): string => (value.map((mode) => (i18n.t(`welcome.gateway.modes.${mode}`))).join(", ")),
		decodeValue: (value: SmartModes): SmartModes => (value),
		encodeValue: (value: SmartModes): SmartModes => (value),
	},
	color: {
		id: "color",
		printValue: (value: ColorHex): ReactElement => (<ColorCircle color={value} />), // eslint-disable-line react/display-name
		decodeValue: (value: ColorXYString, cluster: IncapsCluster0300): ColorHex => {
			const valuesCurrentXY = value.split(",").map((v) => (hex2Decimal(v))) as [number, number];
			const colorPrimaries = getColorPrimariesFromCluster(cluster);
			const colorRGB = convertColorXYToColorRGB(valuesCurrentXY[0], valuesCurrentXY[1], colorPrimaries);
			return rgbToHex(colorRGB);
		},
		encodeValue: (value: ColorRGB, cluster: IncapsCluster0300): ColorXYString => {
			const colorPrimaries = getColorPrimariesFromCluster(cluster);
			const { currentX, currentY } = convertColorRGBToColorXY(value, colorPrimaries);
			return `${decimal2Hex(currentX, 4)},${decimal2Hex(currentY, 4)}`;
		},
	},
	colorTemperature: {
		id: "color_temperature",
		printValue: (value: number): ReactElement => (<ColorCircle color={convertMiredToHex(value)} />), // eslint-disable-line react/display-name
		decodeValue: (value: string): number => (hex2Decimal(value)),
		encodeValue: (value: number): string => (decimal2Hex(value, 4)),
	},
	dimLevel: {
		id: "dim_level",
		printValue: (value: number): string => (`${numberFormatter(value)}${Constants.Units.Percent}`),
		decodeValue: (value: string): number => (Math.round(hex2Decimal(value) / 255 * 100)),
		encodeValue: (value: number): string => (decimal2Hex(Math.round(value / 100 * 255), 2)),
	},
	curtainPosition: {
		id: "curtain_position",
		printValue: (value: number): string => (`${numberFormatter(value)}${Constants.Units.Percent}`),
		decodeValue: (value: string): number => (hex2Decimal(value)),
		encodeValue: (value: number): string => (decimal2Hex(value, 2)),
	},
	nukiState: {
		id: "nuki_state",
		printValue: (value: number, cluster: IncapsClusterFF92): string => {
			const nukiDeviceType = cluster[ClusterConstants.DFF92.Attributes.NukiDeviceType];
			if (nukiDeviceType === Constants.NukiDeviceTypes.DoorLock) {
				return i18n.t([`clusters.DFF92.doorLockStates.${value}`, "clusters.DFF92.doorLockStates.255"] as const);
			} else if (nukiDeviceType === Constants.NukiDeviceTypes.Opener) {
				return i18n.t([`clusters.DFF92.openerStates.${value}`, "clusters.DFF92.openerStates.255"] as const);
			}
			return "";
		},
		decodeValue: (value: number): number => (value),
		encodeValue: (value: number): number => (value),
	},
	daysOfWeek: {
		id: "dow",
		printValue: (days: Days): string => (days.map((day) => (getWeekDayL10n(day))).join(", ")),
		decodeValue: (value: Days): Days => (value),
		encodeValue: (value: Days): Days => (value),
	},
	checkLawnMowerAction: {
		id: "check_mower_action",
		printValue: (value: number): string => {
			const mowerMode = Constants.AvailableMowerModes.find((mowerMode) => (mowerMode.value === value));
			return mowerMode ? i18n.t(mowerMode.l10n) : "";
		},
		decodeValue: (value: number): number => (value),
		encodeValue: (value: number): number => (value),
	},
	lawnMowerAction: {
		id: "mower_action",
		printValue: (value: string): string => {
			const mowerMode = Constants.AvailableMowerActionModes.find((mowerMode) => (mowerMode.value === value));
			return mowerMode ? i18n.t(mowerMode.l10n) : "";
		},
		decodeValue: (value: string): string => (value),
		encodeValue: (value: string): string => (value),
	},
	checkThermostatMode: {
		id: "check_thermostat_mode",
		printValue: (value: string): string => {
			const thermostatMode = Constants.ThermostatModes.find((thermostatMode) => (thermostatMode.value === value));
			return thermostatMode ? i18n.t(`clusters.DFF80.modes.${thermostatMode.l10n}`) : "";
		},
		decodeValue: (value: number): string => (decimal2Hex(value, 2)),
		encodeValue: (value: string): number => (hex2Decimal(value)),
	},
	thermostatMode: {
		id: "thermostat_mode",
		printValue: (value: string): string => {
			const thermostatMode = Constants.ThermostatModes.find((thermostatMode) => (thermostatMode.value === value));
			return thermostatMode ? i18n.t(`clusters.DFF80.modes.${thermostatMode.l10n}`) : "";
		},
		decodeValue: (value: string): string => (value),
		encodeValue: (value: string): string => (value),
	},
	time: {
		id: "time",
		printValue: (hoursMinutes: [number, number]): ReactElement => {
			const dateTime = hoursMinutes.map((value) => (
				value.toString().padStart(2, "0")
			)).join(":");
			return <time dateTime={dateTime}>{dateTime}</time>;
		},
		decodeValue: (value: number, value2: number): [number, number] => ([value, value2]),
		encodeValue: (value: Date): number => (value.getHours()), // TODO value.getMinutes()
	},
	timeBetween: {
		id: "time_between",
		printValue: (value: [Date, Date]): JSX.Element => {
			const [startDate, endDate] = value;
			const startDateString = startDate.toLocaleTimeString("en", { hour12: false, hour: "2-digit", minute: "2-digit" });
			const endDateString = endDate.toLocaleTimeString("en", { hour12: false, hour: "2-digit", minute: "2-digit" });
			return (
				<ReactMultipleStringReplace
					originalString={i18n.t("general.fromToValueSeperator")}
					replaceData={{
						"{VALUE_FROM}": (match) => (
							<time key={match} dateTime={startDateString}>{startDateString}</time>
						),
						"{VALUE_TO}": (match) => (
							<time key={match} dateTime={endDateString}>{endDateString}</time>
						),
					}}
				/>
			);
		},
		decodeValue: (value: [number, number]): [Date, Date] => (
			value.map((v, index) => {
				const date = new Date();
				date.setHours(0, (index === 1 && v === 24 * 60) ? 0 : v, 0, 0);
				return date;
			}) as [Date, Date]
		),
		encodeValue: (value: [Date, Date]): [number, number] => (
			value.map((v, index) => ((index === 1 && v.getHours() === 0 && v.getMinutes() === 0) ? 24 * 60 : v.getHours() * 60 + v.getMinutes()))
		) as [number, number],
	},
	powerConsumption: {
		id: "power_consumption",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.Watt}`),
		decodeValue: (value: number, cluster: IncapsCluster0702): number => {
			const multiplier = (typeof cluster[ClusterConstants.D0702.Attributes.Multiplier] === "number") ? cluster[ClusterConstants.D0702.Attributes.Multiplier] as number : 1;
			const divisor = (typeof cluster[ClusterConstants.D0702.Attributes.Divisor] === "number") ? cluster[ClusterConstants.D0702.Attributes.Divisor] as number : 1;
			return roundToPrecision(value * 1000 * multiplier / divisor);
		},
		encodeValue: (value: number, cluster: IncapsCluster0702): number => {
			const multiplier = (typeof cluster[ClusterConstants.D0702.Attributes.Multiplier] === "number") ? cluster[ClusterConstants.D0702.Attributes.Multiplier] as number : 1;
			const divisor = (typeof cluster[ClusterConstants.D0702.Attributes.Divisor] === "number") ? cluster[ClusterConstants.D0702.Attributes.Divisor] as number : 1;
			return roundToPrecision(value * divisor / (1000 * multiplier));
		},
	},
	powerProduction: {
		id: "power_production",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.Watt}`),
		decodeValue: (value: number, cluster: IncapsCluster0702): number => {
			const multiplier = (typeof cluster[ClusterConstants.D0702.Attributes.Multiplier] === "number") ? cluster[ClusterConstants.D0702.Attributes.Multiplier] as number : 1;
			const divisor = (typeof cluster[ClusterConstants.D0702.Attributes.Divisor] === "number") ? cluster[ClusterConstants.D0702.Attributes.Divisor] as number : 1;
			return roundToPrecision(value * 1000 * multiplier / divisor) * (("FF51" in cluster) ? 1 : -1);
		},
		encodeValue: (value: number, cluster: IncapsCluster0702): number => {
			const multiplier = (typeof cluster[ClusterConstants.D0702.Attributes.Multiplier] === "number") ? cluster[ClusterConstants.D0702.Attributes.Multiplier] as number : 1;
			const divisor = (typeof cluster[ClusterConstants.D0702.Attributes.Divisor] === "number") ? cluster[ClusterConstants.D0702.Attributes.Divisor] as number : 1;
			return roundToPrecision(value * divisor / (1000 * multiplier)) * (("FF51" in cluster) ? 1 : -1);
		},
	},
	pm25Particle: {
		id: "pm2_5",
		printValue: (value: number): string => (`${numberFormatter(value)} ${Constants.Units.MicroGramPerCubicMeter}`),
		decodeValue: (value: number): number => (value),
		encodeValue: (value: number): number => (value),
	},
	applianceOperationMode: {
		id: "appliance_operation_mode",
		printValue: (value: number): string => {
			const operationState = Constants.HomeConnect.AvailableOperationState.find((operationState) => (operationState.value === value));
			return operationState ? i18n.t(operationState.l10n) : "";
		},
		decodeValue: (value: number): number => (value),
		encodeValue: (value: number): number => (value),
	},
	doorState: {
		id: "door_state",
		printValue: (value: number): string => {
			const doorLockState = Constants.HomeConnect.DoorLockStates.find((doorLockState) => (doorLockState.value === value));
			return doorLockState ? i18n.t(doorLockState.l10n) : "";
		},
		decodeValue: (value: number): number => (value),
		encodeValue: (value: number): number => (value),
	},
	predefined: {
		id: "predefined",
		printValue: (value: string): string => (value),
		decodeValue: (value: string): string => (value),
		encodeValue: (value: string): string => (value),
	},
} as const;

export default RuleCategory;
