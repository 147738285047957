import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import SecureStorage from "../plugins/SecureStorage";
// services
import Constants from "./constants";

export const getSecureStoreName = async (): Promise<`${string}.xamarinessentials`> => {
	const { id } = await App.getInfo();
	return `${id}.xamarinessentials` as const;
};

export const getPhoneUUIDFromStorage = async (): Promise<string | undefined | null> => {
	const storeName = await getSecureStoreName();
	const secureStoragePhoneId = (await SecureStorage.get({ key: "PHONE_ID", storename: storeName })).data;

	const isIos = Capacitor.getPlatform() === Constants.Platform.iOS;
	let filePhoneId = "";
	if (isIos) {
		try {
			filePhoneId = (await SecureStorage.readPhoneIdFromFile()).phoneId;
		} catch (error) {
			console.error("readPhoneIdFromFile error!", error);
		}
	}

	return isIos ? filePhoneId : secureStoragePhoneId;
};

export const setPhoneUUIDToStorage = async (uuid: string): Promise<void> => {
	const storeName = await getSecureStoreName();
	await SecureStorage.set({ key: "PHONE_ID", storename: storeName, data: uuid });

	await Preferences.set({ key: "PHONE_ID", value: uuid });

	if (Capacitor.getPlatform() === Constants.Platform.iOS) {
		await SecureStorage.setAccessible({ key: "PHONE_ID_ACCESSIBLE", storename: storeName, data: uuid });
		try {
			await SecureStorage.writePhoneIdToFile({ phoneId: uuid });
		} catch (error) {
			console.error("writePhoneIdToFile in setPhoneUUIDToStorage error!", error);
		}
	}
};

