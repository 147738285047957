import { EventEmitter } from "events";
import { defaultServerOptionId } from "./serverOption";
import Constants from "./constants";
// @ts-expect-error: window.appInfo
import appInfo from "@local/appInfo";
// types
import type { Channel, ServerId } from "../types/config";
import type { OAuthStateObj } from "../types/user";
import type { TableName, Table, WidgetId } from "../types/roc-table";
import type { GatewayId } from "../types/gateway";
import type { DeviceId, FavoriteDeviceIds } from "../types/device";
import type { RoomId } from "../types/room";
import type { SceneId } from "../types/scenes";
import type { Appearance, Currency, InAppReviewData } from "../types/misc";
import type { AppInfo } from "../types/global";
import type { ProviderId, ConfigCluster, OldAccount, StateObjs, AccountsGateways } from "../types/deprecated";

export const CURRENT_STORAGE_VERSION = 1;

export const StorageKeys = {
	storageVersion: "storageVersion",
	selectedBackendServer: "selectedBackendServer",
	launchUrl: "launchUrl",
	expertMode: "expertMode",
	oAuthState: "oAuthState",
	selectedGatewayId: "selectedGatewayId",
	welcomeTextEnabled: "welcomeTextEnabled/{GWID}",
	quickviewSettingsSectionsOrder: "quickviewSettingsSectionsOrder/{GWID}",
	smartWidgetIds: "smartWidgetIds/{GWID}",
	favoriteDeviceIds: "favoriteDeviceIds/{GWID}",
	favoriteRoomsOrder: "favoriteRoomsOrder/{GWID}",
	favoriteRoomDevicesOrder: "favoriteRoomDevicesOrder/{GWID}/{ROOM_ID}",
	sceneIds: "sceneIds/{GWID}",
	appearance: "appearance",
	cookieStatus: "cookie-status",
	accountDeviceId: "account/deviceId",
	rocTable: "roctable/{TABLE_NAME}",
	rocTableVersion: "roctable/{TABLE_NAME}/version",
	vaccumFullMenuView: "vaccumFullMenuView",
	hideNotificationBanner: "hideNotificationBanner",
	noOverlapTimeForOfflinePassword: "noOverlapTimeForOfflinePassword",
	electricityUnitPriceValue: "electricityUnitPriceValue/{GWID}",
	electricityFeedInUnitPriceValue: "electricityFeedInUnitPriceValue/{GWID}",
	electricityUnitPriceCurrency: "electricityUnitPriceCurrency/{GWID}",
	appVersion: "appVersion",
	inAppReviewData: "inAppReviewData",
	showAndroidVersionAlert: "showAndroidVersionAlert",
	/** @deprecated */
	menu: "menu/{MENU_ID}",
	/** @deprecated */
	settingsCluster: "settings/cluster",
	/** @deprecated */
	settingsChannel: "settings/channel",
	/** @deprecated */
	accounts: "accounts",
	/** @deprecated */
	accountsDefaults: "accounts/defaults",
	/** @deprecated */
	providers: "providers/{PROVIDER_ID}/{KEY}",
	/** @deprecated */
	accountsGateways: "accounts/gateways",
} as const;

interface StorageValues {
	[StorageKeys.storageVersion]: number;
	[StorageKeys.selectedBackendServer]: ServerId;
	[StorageKeys.launchUrl]: string;
	[StorageKeys.expertMode]: boolean;
	[StorageKeys.oAuthState]: OAuthStateObj;
	[StorageKeys.selectedGatewayId]: GatewayId;
	[StorageKeys.welcomeTextEnabled]: boolean;
	[StorageKeys.quickviewSettingsSectionsOrder]: ReadonlyArray<(typeof Constants.QuickviewSettingsDefaultSectionsOrder)[number]>;
	[StorageKeys.smartWidgetIds]: Array<WidgetId>;
	[StorageKeys.favoriteDeviceIds]: FavoriteDeviceIds;
	[StorageKeys.favoriteRoomsOrder]: Array<RoomId>;
	[StorageKeys.favoriteRoomDevicesOrder]: Array<DeviceId>;
	[StorageKeys.sceneIds]: Array<SceneId>;
	[StorageKeys.appearance]: Appearance;
	[StorageKeys.cookieStatus]: "closed";
	[StorageKeys.accountDeviceId]: DeviceId;
	[StorageKeys.rocTable]: Table;
	[StorageKeys.rocTableVersion]: number;
	[StorageKeys.vaccumFullMenuView]: boolean;
	[StorageKeys.hideNotificationBanner]: boolean;
	[StorageKeys.noOverlapTimeForOfflinePassword]: number;
	[StorageKeys.electricityUnitPriceValue]: number;
	[StorageKeys.electricityFeedInUnitPriceValue]: number;
	[StorageKeys.electricityUnitPriceCurrency]: Currency;
	[StorageKeys.appVersion]: string;
	[StorageKeys.inAppReviewData]: InAppReviewData;
	[StorageKeys.showAndroidVersionAlert]: boolean;
	/** @deprecated */
	[StorageKeys.menu]: boolean;
	/** @deprecated */
	[StorageKeys.settingsCluster]: ConfigCluster;
	/** @deprecated */
	[StorageKeys.settingsChannel]: Channel;
	/** @deprecated */
	[StorageKeys.accounts]: Array<OldAccount>;
	/** @deprecated */
	[StorageKeys.accountsDefaults]: Array<OldAccount>;
	/** @deprecated */
	[StorageKeys.providers]: StateObjs;
	/** @deprecated */
	[StorageKeys.accountsGateways]: AccountsGateways;
}

const DEFAULT_VALUES = {
	[StorageKeys.storageVersion]: 0,
	[StorageKeys.selectedBackendServer]: defaultServerOptionId,
	[StorageKeys.launchUrl]: null,
	[StorageKeys.expertMode]: false,
	[StorageKeys.oAuthState]: undefined,
	[StorageKeys.selectedGatewayId]: undefined,
	[StorageKeys.welcomeTextEnabled]: true,
	[StorageKeys.quickviewSettingsSectionsOrder]: Constants.QuickviewSettingsDefaultSectionsOrder,
	[StorageKeys.smartWidgetIds]: undefined,
	[StorageKeys.favoriteDeviceIds]: [] as FavoriteDeviceIds,
	[StorageKeys.favoriteRoomsOrder]: undefined,
	[StorageKeys.favoriteRoomDevicesOrder]: undefined,
	[StorageKeys.sceneIds]: [] as Array<SceneId>,
	[StorageKeys.appearance]: Constants.Appearance.System,
	[StorageKeys.cookieStatus]: null,
	[StorageKeys.accountDeviceId]: null,
	[StorageKeys.rocTable]: [] as Table,
	[StorageKeys.rocTableVersion]: -1,
	[StorageKeys.vaccumFullMenuView]: false,
	[StorageKeys.hideNotificationBanner]: false,
	[StorageKeys.noOverlapTimeForOfflinePassword]: new Date().setMinutes(0, 0, 0),
	[StorageKeys.electricityUnitPriceValue]: null,
	[StorageKeys.electricityFeedInUnitPriceValue]: null,
	[StorageKeys.electricityUnitPriceCurrency]: null,
	[StorageKeys.appVersion]: undefined,
	[StorageKeys.inAppReviewData]: {} as InAppReviewData,
	[StorageKeys.showAndroidVersionAlert]: true,
	/** @deprecated */
	[StorageKeys.menu]: false,
	/** @deprecated */
	[StorageKeys.settingsCluster]: undefined,
	/** @deprecated */
	[StorageKeys.settingsChannel]: undefined,
	/** @deprecated */
	[StorageKeys.accounts]: [] as Array<OldAccount>,
	/** @deprecated */
	[StorageKeys.accountsDefaults]: [] as Array<OldAccount>,
	/** @deprecated */
	[StorageKeys.providers]: [] as StateObjs,
	/** @deprecated */
	[StorageKeys.accountsGateways]: [] as AccountsGateways,
}; // TODO: satisfies StorageValues; // TODO: as const

// See "{...}" in StorageKeys
export interface ReplaceData {
	GWID?: GatewayId;
	ROOM_ID?: RoomId;
	TABLE_NAME?: TableName;
	/** @deprecated */
	MENU_ID?: string;
	/** @deprecated */
	PROVIDER_ID?: ProviderId;
	/** @deprecated */
	KEY?: string;
}
type StorageKeyPrefix = "" | `${string}/${string}/`;
export type StorageKey = keyof StorageValues;
type StorageKeyWithPrefix<SK extends StorageKey = StorageKey> = `${StorageKeyPrefix}${SK}`;
type StorageValue<SK extends StorageKey = StorageKey> = StorageValues[SK] | null;
export type StorageValueWithDefault<SK extends StorageKey = StorageKey> = StorageValues[SK] | (typeof DEFAULT_VALUES)[SK];
type StorageType = "localStorage" | "sessionStorage" | "memory";

interface StorageT<ST extends StorageType = StorageType> {
	type: ST;
	isAvailable: () => boolean;
	needsPrefix: () => boolean;
	updateItems: (storageKeyPrefix: StorageKeyPrefix) => void;
	getItem: (key: StorageKeyWithPrefix) => StorageValue;
	setItem: (key: StorageKeyWithPrefix, value: StorageValue) => void;
	removeItem: (key: StorageKeyWithPrefix) => void;
	getKeys: () => Set<StorageKeyWithPrefix>;
	clear: () => void;
}

/**
 * A service class responsible to store the data in browser.
 * The storage used is localstorage then sessionStorage then memory.
 */

const getLocalOrSessionStorage = <ST extends "localStorage" | "sessionStorage">(storageType: ST) => ({
	type: storageType,
	isAvailable: () => {
		try {
			const storageTestString = "__storage_test__";
			window[storageType].setItem(storageTestString, storageTestString);
			window[storageType].removeItem(storageTestString);
			return true;
		} catch (e) {
			return false;
		}
	},
	needsPrefix: () => true,
	updateItems: (storageKeyPrefix) => {
		for (let i = 0; i < window[storageType].length; i++) {
			const key = window[storageType].key(i);
			if (key?.startsWith(storageKeyPrefix)) {
				const value = window[storageType].getItem(key);
				try {
					JSON.parse(value);
				} catch (e) {
					window[storageType].setItem(key, JSON.stringify(value));
				}
			}
		}
	},
	getItem: (key) => (JSON.parse(window[storageType].getItem(key)) as StorageValue),
	setItem: (key, value) => (window[storageType].setItem(key, JSON.stringify(value))),
	removeItem: (key) => (window[storageType].removeItem(key)),
	getKeys: () => {
		const keys = new Set<StorageKeyWithPrefix>();
		for (let i = 0; i < window[storageType].length; i++) {
			keys.add(window[storageType].key(i) as StorageKeyWithPrefix);
		}
		return keys;
	},
	clear: () => (window[storageType].clear()),
} satisfies StorageT<ST>);

const memoryStorage = new Map<StorageKeyWithPrefix, StorageValue>();

const localStorage = getLocalOrSessionStorage("localStorage");
const sessionStorage = getLocalOrSessionStorage("sessionStorage");
const memory = {
	type: "memory",
	isAvailable: () => true,
	needsPrefix: () => false,
	updateItems: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
	getItem: (key) => (memoryStorage.get(key) ?? null),
	setItem: (key, value) => (memoryStorage.set(key, value)),
	removeItem: (key) => (memoryStorage.delete(key)),
	getKeys: () => (new Set(memoryStorage.keys())),
	clear: () => (memoryStorage.clear()),
} satisfies StorageT<"memory">;

export const DEFAULT_REPLACE_DATA = {} as const satisfies ReplaceData;

class StorageApi extends EventEmitter {

	#storage: StorageT;
	#storageKeyPrefix: StorageKeyPrefix = "";

	constructor() {
		super();

		this.setMaxListeners(256); // a listener per device in the devices view

		const storages = [localStorage, sessionStorage] as const;
		this.#storage = storages.find((storage) => (storage.isAvailable())) ?? memory;

		if (this.#storage.needsPrefix()) {
			const lastSlashIndex = window.location.pathname.lastIndexOf("/");
			const pathname = (lastSlashIndex === -1) ? window.location.pathname : window.location.pathname.substring(0, lastSlashIndex);
			this.#storageKeyPrefix = `${pathname}/${(appInfo as AppInfo).name}/` as StorageKeyPrefix;
		}

		this.#storage.updateItems(this.#storageKeyPrefix);

		if (this.#storage.type === "localStorage") {
			// "storage" event fires if local-storage got modified by other window
			window.addEventListener("storage", ({ key, newValue }) => {
				if (key?.startsWith(this.#storageKeyPrefix)) {
					const storageKey = Object.values(StorageKeys).find((storageKey) => (
						(new RegExp(`^${this.#storageKeyPrefix}${storageKey.replaceAll(/\{\w+\}/g, "[^/]+")}$`)).test(key)
					));
					if (storageKey) {
						const replaceDataValues = new RegExp(`^${this.#storageKeyPrefix}${storageKey.replaceAll(/\{(?<key>\w+)\}/g, "(?<$<key>>[^/]+)")}$`).exec(key);
						const replaceData = replaceDataValues?.groups as ReplaceData | undefined ?? {};
						for (const key of Object.keys(replaceData)) {
							const value = replaceData[key];
							if (value) {
								replaceData[key] = decodeURIComponent(value);
							}
						}
						if (newValue === null) {
							this.emit(`${storageKey}Removed`, replaceData);
						} else {
							this.emit(`${storageKey}Changed`, JSON.parse(newValue) as StorageValueWithDefault, replaceData);
						}
					}
				}
			});
		}
	}

	#getStorageKey<SK extends StorageKey>(storageKey: SK, replaceData: ReplaceData): StorageKeyWithPrefix<SK> {
		let key: string = storageKey;

		for (const replaceKey of Object.keys(replaceData)) {
			key = key.replaceAll(new RegExp(`{${replaceKey}}`, "g"), encodeURIComponent(replaceData[replaceKey]));
		}

		return `${this.#storageKeyPrefix}${key}` as StorageKeyWithPrefix<SK>;
	}

	public type(): StorageType {
		return this.#storage.type;
	}

	public clear(): void {
		this.#storage.clear();
	}

	public has<SK extends StorageKey>(storageKey: SK, replaceData: ReplaceData = DEFAULT_REPLACE_DATA): boolean {
		return this.#storage.getItem(this.#getStorageKey(storageKey, replaceData)) as StorageValue<SK> !== null;
	}

	public get<SK extends StorageKey>(storageKey: SK, replaceData: ReplaceData = DEFAULT_REPLACE_DATA, fallbackReplaceData?: ReplaceData): StorageValueWithDefault<SK> {
		if (fallbackReplaceData === undefined || this.has(storageKey, replaceData)) {
			return this.#storage.getItem(this.#getStorageKey(storageKey, replaceData)) as StorageValue<SK> ?? DEFAULT_VALUES[storageKey];
		}
		const value = this.#storage.getItem(this.#getStorageKey(storageKey, fallbackReplaceData)) as StorageValue<SK> ?? DEFAULT_VALUES[storageKey];
		if (this.has(storageKey, fallbackReplaceData)) {
			this.set(storageKey, value, replaceData);
			this.remove(storageKey, fallbackReplaceData);
		}
		return value;
	}

	public set<SK extends StorageKey>(storageKey: SK, storageValue: StorageValueWithDefault<SK>, replaceData: ReplaceData = DEFAULT_REPLACE_DATA): void {
		this.#storage.setItem(this.#getStorageKey(storageKey, replaceData), storageValue);
		this.emit(`${storageKey}Changed`, storageValue, replaceData);
	}

	public remove<SK extends StorageKey>(storageKey: SK, replaceData: ReplaceData = DEFAULT_REPLACE_DATA): void {
		this.#storage.removeItem(this.#getStorageKey(storageKey, replaceData));
		this.emit(`${storageKey}Removed`, replaceData);
	}

	public getKeys(): Set<StorageKey> {
		if (this.#storage.needsPrefix()) {
			const keys = Array.from(this.#storage.getKeys())
				.filter((storageKeyWithPrefix) => (storageKeyWithPrefix.startsWith(this.#storageKeyPrefix)))
				.map((storageKeyWithPrefix) => (storageKeyWithPrefix.substring(this.#storageKeyPrefix.length))) as ReadonlyArray<StorageKey>;

			return new Set(keys);
		} else {
			return this.#storage.getKeys() as Set<StorageKey>;
		}
	}

	public getAll(): Map<StorageKey, StorageValueWithDefault> {
		const data = new Map<StorageKey, StorageValueWithDefault>();
		for (const storageKey of this.getKeys()) {
			data.set(storageKey, this.get(storageKey));
		}
		return data;
	}

	public logoutCleanup(): void {
		// update unit test if `KEEP_STORAGE_KEYS` array gets changed
		const KEEP_STORAGE_KEYS = [
			StorageKeys.selectedBackendServer,
			StorageKeys.launchUrl,
			StorageKeys.expertMode,
			StorageKeys.oAuthState,
			// StorageKeys.appVersion,
		] as const satisfies ReadonlyArray<StorageKey>;

		const regexKeepStorageKeys = KEEP_STORAGE_KEYS.map((keepStorageKey) => (this.#getStorageKeyRegex(keepStorageKey)));

		for (const storageKey of this.getKeys()) {
			if (!regexKeepStorageKeys.some((regexKeepStorageKey) => (regexKeepStorageKey.test(storageKey)))) {
				this.remove(storageKey);
			}
		}
	}

	public migrate(): void {
		/* eslint-disable no-fallthrough */
		switch (this.get(StorageKeys.storageVersion)) {
			case 0:
				this.#migrateToVersion1();
			// case 1:
			// 	this.#migrateToVersion2();
			// ! don't forget to update CURRENT_STORAGE_VERSION
		}
		/* eslint-enable no-fallthrough */

		this.set(StorageKeys.storageVersion, CURRENT_STORAGE_VERSION);
	}

	#getStorageKeyRegex(storageKey: StorageKey): RegExp {
		return new RegExp(`^${storageKey.replaceAll(/{[^}]+}/g, ".+")}$`);
	}

	#migrateToVersion1(): void {
		this.#setSelectedBackendServerAndGatewayIdAndRemoveOldData();
		this.#removeOldUnusedData();
		this.#migrateOldVersionKeys();
		this.#storeReplaceParamsEncoded();
	}

	#storeReplaceParamsEncoded(): void {
		const STORAGE_KEYS_TO_ENCODE = [
			StorageKeys.welcomeTextEnabled, // GWID
			StorageKeys.quickviewSettingsSectionsOrder, // GWID
			StorageKeys.smartWidgetIds, // GWID
			StorageKeys.favoriteDeviceIds, // GWID
			StorageKeys.favoriteRoomsOrder, // GWID
			StorageKeys.favoriteRoomDevicesOrder, // GWID,ROOM_ID
			StorageKeys.sceneIds, // GWID
			StorageKeys.electricityUnitPriceValue, // GWID
			StorageKeys.electricityFeedInUnitPriceValue, // GWID
			StorageKeys.electricityUnitPriceCurrency, // GWID
		] as const satisfies ReadonlyArray<StorageKey>;

		for (const storageKey of this.getKeys()) {
			for (const storageKeyToEncode of STORAGE_KEYS_TO_ENCODE) {
				if (this.#getStorageKeyRegex(storageKeyToEncode).test(storageKey)) {
					const replaceDataValues = new RegExp(`^${storageKeyToEncode.replaceAll(/{GWID}/g, "(?<GWID>.+)").replaceAll(/{ROOM_ID}/g, String.raw`(?<ROOM_ID>\w+)`)}$`).exec(storageKey);
					if (replaceDataValues?.groups) {
						const replaceData = replaceDataValues.groups satisfies ReplaceData;
						const storageValue = this.get(storageKey); // replaceData would get double encoded
						this.set(storageKeyToEncode, storageValue, replaceData);
						this.remove(storageKey); // replaceData would get double encoded
					}
				}
			}
		}
	}

	#setSelectedBackendServerAndGatewayIdAndRemoveOldData(): void {
		if (!this.has(StorageKeys.selectedBackendServer)) {
			this.set(StorageKeys.selectedBackendServer, defaultServerOptionId);
			const accountsGateways = this.get(StorageKeys.accountsGateways);
			const gatewayId = accountsGateways[0]?.gateway?.id;
			if (gatewayId) {
				this.set(StorageKeys.selectedGatewayId, gatewayId);
			}
		}
	}

	#removeOldUnusedData(): void {
		this.remove(StorageKeys.settingsCluster);
		this.remove(StorageKeys.settingsChannel);
		this.remove(StorageKeys.accounts);
		this.remove(StorageKeys.accountsDefaults);
		this.remove(StorageKeys.providers, { PROVIDER_ID: "oauth2-cookie-1", KEY: "loginStates" });
		this.remove(StorageKeys.providers, { PROVIDER_ID: "oauth2-cookie-1", KEY: "logoutStates" });
		this.remove(StorageKeys.accountsGateways);
		// removed after capacitor migration
		this.remove(StorageKeys.menu, { MENU_ID: "submenu-settings" });
	}

	#migrateOldVersionKeys(): void {
		for (const storageKey of this.getKeys()) {
			if (this.#getStorageKeyRegex(StorageKeys.favoriteDeviceIds).test(storageKey)) {
				this.#migrateOldFavoriteDeviceIds(storageKey as typeof StorageKeys.favoriteDeviceIds);
			} else if (this.#getStorageKeyRegex(StorageKeys.quickviewSettingsSectionsOrder).test(storageKey)) {
				this.#migrateOldQuickviewSettingsSectionsOrder(storageKey as typeof StorageKeys.quickviewSettingsSectionsOrder);
			}
		}
	}

	#migrateOldFavoriteDeviceIds(storageKey: typeof StorageKeys.favoriteDeviceIds): void {
		const value = this.get(storageKey);
		if (value.some((item) => (typeof item === "string"))) {
			this.set(storageKey, value.map((item) => ({ id: item as unknown as DeviceId, epId: null })));
		}
	}

	#migrateOldQuickviewSettingsSectionsOrder(storageKey: typeof StorageKeys.quickviewSettingsSectionsOrder): void {
		const value = this.get(storageKey);
		const favoriteScenesValue = Constants.QuickviewSettingsDefaultSectionsOrder[2];
		if (!value.includes(favoriteScenesValue)) {
			this.set(storageKey, [...value, favoriteScenesValue]);
		}
	}

}

export const Storage = new StorageApi();
